import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import ReactJson from 'react-json-view';
import { FormattedDate, FormattedNumber, FormattedRelative } from 'react-intl';
import Money from '../lib/Money';
import TxResult from '../lib/TxResult';
import TxType from '../lib/TxType';
import LedgerTag from '../lib/LedgerTag';
import CardTitleContainer from '../lib/CardTitleContainer';
import { EmptyTransaction } from '../notfound/NotFound';
import TransactionMemo from './TransactionMemo';
import AccountTag from '../lib/AccountTag';
import XBadge from '../lib/XBadge';
import XIcon from '../lib/XIcon';
import Callout from '../lib/Callout';
import XIconBadge from '../lib/XIconBadge';
import TransactionResultDescription from './TransactionResultDescription';
import {
	getTxResultStyle,
	getEnableAmendmentStyle,
	getAmendmentStatus,
	getEscrowNodeAmount,
	isTokenBurnTx,
	transferFeeToPercent,
	hex2ascii,
	getPrice,
	currencyName,
} from '../common/Helpers';
import {
	Burn2MintTransactionTypes,
	DROPS_PER_XRP,
	NATIVE_CURRENCY,
	TransactionType,
	XrplNetwork
} from '../common/Constants';
import AssetTag from '../lib/AssetTag';


class TransactionInfo extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.getExchangeRate = this.getExchangeRate.bind(this);

		this.state = {
			tx: this.props.tx,
			error: this.props.error,
			show: false,
		};
	}

	handleShow() {
		this.setState({ show: true });
	}

	handleClose() {
		this.setState({ show: false });
	}

	/* 
		Calculate exchange rates by converting drops to XRP amounts.
	*/
	getExchangeRate(pay, get) {
		if (get.value === 0) {
			return 0;
		}
		if (pay.currency === NATIVE_CURRENCY) {
			return ((pay.value / DROPS_PER_XRP) / get.value);
		}
		else if (get.currency === NATIVE_CURRENCY) {
			return (pay.value / (get.value / DROPS_PER_XRP));
		}
		else if (pay.value > 0 && get.value > 0) {
			return (pay.value / get.value);
		}
		else {
			return 0;
		}
	}

	/*
		Was the AccountDelete transaction successful?
	*/
	getAccountDeleteSuccess(tx) {
		if (tx && tx.TransactionType === 'AccountDelete' && tx.meta && tx.meta.TransactionResult === 'tesSUCCESS') {
			return true;
		}
		else {
			return false;
		}
	}

	/*
	* Return component to describe TransactionResult error codes
	*/
	getTransactionResultDescription(result) {
		if (result && !result.startsWith("tes") && Object.keys(TransactionResultDescription).includes(result) ) {
			return (
				<Row>
					<Col xs={12} md="12">
						<Callout title="FAILED" variant="danger">
						&nbsp; {TransactionResultDescription[result]}
						</Callout>
					</Col>
				</Row>
			);
		}
	}


	render() {
		const { tx, error } = this.state;

		if (error) {
			return <div className="error">{error}</div>
		}
		else if (Object.keys(tx).length === 0) {
			return <EmptyTransaction/>
		}

		return (
			<Card className="shadow-sm">
				<Card.Body>
					{tx && tx.meta && tx.meta.TransactionResult && !(tx.meta.TransactionResult.startsWith("tes")) &&
						this.getTransactionResultDescription(tx.meta.TransactionResult)
					}
					{tx.TransactionType === TransactionType.EscrowFinish && tx.SigningPubKeyAccount !== tx.Owner &&
						<Callout variant="success" title="ESCROW NOTE">
							<span className='ml-2'>
								This Escrow release was <strong>not</strong> initiated by the Escrow owner. Any account may submit an EscrowFinish transaction to release a completed escrow and add a custom Memo.
								<Alert.Link href="https://xrpl.org/docs/concepts/payment-types/escrow#escrow-lifecycle" target="_blank" className='ml-1'>Read more &rarr;</Alert.Link>
							</span>
						</Callout>
					}
					<Card.Title>
						<CardTitleContainer icon="file-invoice" title="Transaction summary" />
					</Card.Title>
					<Row>
						<Col xs={12} md={8}>
							<Table responsive>
								<thead>
									<tr>
										<th>Specification</th>
										<th><TxType type={tx.TransactionType} /></th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Tx hash:</td>
										<td><div className="hash text-muted">{tx.hash}</div></td>
									</tr>

									{tx.ctid &&
									<tr>
										<td>CTID:</td>
										<td><div className="hash text-muted"><Link to={`/tx/${tx.ctid}`}>{tx.ctid}</Link></div></td>
									</tr>
									}

									<tr>
										<td>Date:</td>
										<td>
											<FormattedDate 
												value={tx.date}
												year='numeric' 
												month='short' 
												day='2-digit' 
												hour='2-digit' 
												minute='2-digit' 
												second='2-digit'
												timeZone='UTC'
												timeZoneName='short'
											/>
											<XBadge variant="secondary" className="ml-2 text-uppercase">
												<FormattedRelative value={tx.date} />
											</XBadge>
										</td>
									</tr>
									<tr>
										<td>Source:</td>
										<td>
											<AccountTag name={tx.AccountName} link st={tx.SourceTag}>{tx.Account}</AccountTag>
											{ this.getAccountDeleteSuccess(tx) &&
												<span>&nbsp;<XBadge variant="danger">DELETED</XBadge></span>
											}
										</td>
									</tr>

									{ tx.Destination &&
									<tr>
										<td>Destination:</td>
										<td>
											<AccountTag name={tx.DestinationName} link dt={tx.DestinationTag}>{tx.Destination}</AccountTag>
										</td>
									</tr>
									}

									{ tx.SigningPubKeyAccount && tx.SigningPubKeyAccount !== tx.Account &&
									<tr>
										<td>Signing key:</td>
										<td><AccountTag link>{tx.SigningPubKeyAccount}</AccountTag></td>
									</tr>
									}

									{tx.Amendment &&
									<tr>
										<td>Amendment:</td>
										<td>
											<span><Link to={`/amendment/${tx.Amendment}`}>{(tx.AmendmentName && tx.AmendmentName.name) ? tx.AmendmentName.name : tx.Amendment}</Link></span>
										</td>
									</tr>
									}

									{tx.MessageKey &&
									<tr>
										<td>Message key:</td>
										<td>
											<span className="hash text-muted">{tx.MessageKey}</span>
										</td>
									</tr>
									}

									{tx.RegularKey &&
									<tr>
										<td>Regular key:</td>
										<td>
											<span className="hash text-muted">{tx.RegularKey}</span>
										</td>
									</tr>
									}

									{tx.TicketCount && tx.TicketCount > 0 &&
									<tr>
										<td>Tickets:</td>
										<td>
											<XBadge variant={'primary'} className="text-monospace">
												{tx.Sequence + 1}
											</XBadge>
											{tx.TicketCount > 1 &&
											<>
												<span class="ml-1 mr-1">to</span>
												<XBadge variant={'primary'} className="text-monospace">
													{tx.Sequence + tx.TicketCount}
												</XBadge>
											</>
											}
										</td>
									</tr>
									}

									{tx.ReserveBase &&
									<tr>
										<td>Base reserve:</td>
										<td>
											<Money value={tx.ReserveBase} drops max={6}/>
										</td>
									</tr>
									}
									{tx.ReserveBaseDrops &&
									<tr>
										<td>Base reserve:</td>
										<td>
											<Money value={tx.ReserveBaseDrops} drops max={6}/>
										</td>
									</tr>
									}

									{tx.ReserveIncrement &&
									<tr>
										<td>Owner reserve:</td>
										<td>
											<Money value={tx.ReserveIncrement} drops max={6}/>
										</td>
									</tr>
									}
									{tx.ReserveIncrementDrops &&
									<tr>
										<td>Owner reserve:</td>
										<td>
											<Money value={tx.ReserveIncrementDrops} drops max={6}/>
										</td>
									</tr>
									}

									{tx.ReferenceFeeUnits &&
									<tr>
										<td>Fee units:</td>
										<td>
											<Money value={tx.ReferenceFeeUnits} drops max={6} min={6}/>
										</td>
									</tr>
									}
									{tx.BaseFeeDrops &&
									<tr>
										<td>Base fee:</td>
										<td>
											<Money value={tx.BaseFeeDrops} drops max={6} min={6}/>
										</td>
									</tr>
									}

									{ tx.SendMax && Number(tx?.SendMax?.value) &&
									<tr>
										<td>SendMax:</td>
										<td>
											<Money
												value={tx.SendMax.value}
												currency={tx.SendMax.currency}
												issuer={tx.SendMax.issuer}
												drops
											/>
										</td>
									</tr>
									}
									{ (tx.DeliverMin || tx.DeliverMax) &&
									<tr>
										<td>Deliver:</td>
										<td>
											{tx.DeliverMin && Number(tx?.DeliverMin?.value) &&
											<span className='mr-4'>
												<XBadge variant="primary" className="mr-1">MINIMUM</XBadge>
												<Money
													value={tx.DeliverMin.value}
													currency={tx.DeliverMin.currency}
													issuer={tx.DeliverMin.issuer}
													drops
												/>
											</span>
											}
											{tx.DeliverMax && Number(tx?.DeliverMax?.value) &&
											<span>
												<XBadge variant="success" className="mr-1">MAXIMUM</XBadge>
												<Money
													value={tx.DeliverMax.value}
													currency={tx.DeliverMax.currency}
													issuer={tx.DeliverMax.issuer}
													drops
												/>
											</span>
											}
										</td>
									</tr>
									}
									{ tx.TakerGets &&
									<tr>
										<td>Offer:</td>
										<td>
											<Money
												value={tx.TakerGets.value}
												currency={tx.TakerGets.currency}
												issuer={tx.TakerGets.issuer}
												drops
											/>
										</td>
									</tr>
									}
									{ tx.TakerPays &&
									<tr>
										<td>Receive:</td>
										<td>
											<Money
												value={tx.TakerPays.value}
												currency={tx.TakerPays.currency}
												issuer={tx.TakerPays.issuer}
												drops
											/>
										</td>
									</tr>
									}

									{/* Oracle fields */}
									{ tx.OracleDocumentID >= 0 &&
									<tr>
										<td>Oracle Document ID:</td>
										<td>
											<span>{tx.OracleDocumentID}</span>
										</td>
									</tr>
									}
									{ tx.Provider &&
									<tr>
										<td>Provider:</td>
										<td>
											<span>{hex2ascii(tx.Provider)}</span>
										</td>
									</tr>
									}
									{ tx.AssetClass &&
									<tr>
										<td>Asset class:</td>
										<td>
											<span>{hex2ascii(tx.AssetClass)}</span>
										</td>
									</tr>
									}
									{ tx.LastUpdateTime && Number(tx.LastUpdateTime) && tx.LastUpdateTime >= 0 &&
									<tr>
										<td>Last updated:</td>
										<td>
											<span>
												<FormattedDate
													value={Number(tx.LastUpdateTime) * 1000}
													year='numeric'
													month='short'
													day='2-digit'
													hour='2-digit'
													minute='2-digit'
													second='2-digit'
													timeZone='UTC'
													timeZoneName='short'
												/>
											</span>
											<XBadge variant="secondary" className="ml-2 text-uppercase">
												<FormattedRelative value={tx.LastUpdateTime * 1000} />
											</XBadge>
										</td>
									</tr>
									}

									{/* PaymentChannel fields */}
									{ tx.Channel &&
									<tr>
										<td>Channel:</td>
										<td>
											<div className="hash text-muted">
												<Link to={`/object/${tx.Channel}`}>{tx.Channel}</Link>
											</div>
										</td>
									</tr>
									}
									{ tx.Balance && Number(tx.Balance) >= 0 &&
									<tr>
										<td>Balance:</td>
										<td>
											<Money value={tx.Balance} drops />
										</td>
									</tr>
									}

									{/* AMM fields */}
									{ tx.Amount2 &&
									<tr>
										<td>Amount2:</td>
										<td>
											<Money value={tx.Amount2.value} currency={tx.Amount2.currency} issuer={tx.Amount2.issuer} drops />
										</td>
									</tr>
									}
									{ tx.BidMin &&
									<tr>
										<td>BidMin:</td>
										<td>
											<Money value={tx.BidMin.value} currency={tx.BidMin.currency} issuer={tx.BidMin.issuer} drops />
										</td>
									</tr>
									}
									{ tx.BidMax &&
									<tr>
										<td>BidMax:</td>
										<td>
											<Money value={tx.BidMax.value} currency={tx.BidMax.currency} issuer={tx.BidMax.issuer} drops />
										</td>
									</tr>
									}
									{ tx.Asset &&
									<tr>
										<td>Asset:</td>
										<td>
											<AssetTag value={tx.Asset.value} currency={tx.Asset.currency} issuer={tx.Asset.issuer} drops />
										</td>
									</tr>
									}
									{ tx.Asset2 &&
									<tr>
										<td>Asset2:</td>
										<td>
											<AssetTag value={tx.Asset2.value} currency={tx.Asset2.currency} issuer={tx.Asset2.issuer} drops />
										</td>
									</tr>
									}
									{ tx.EPrice &&
									<tr>
										<td>EPrice:</td>
										<td>
											<Money value={tx.EPrice.value} currency={tx.EPrice.currency} issuer={tx.EPrice.issuer} drops />
										</td>
									</tr>
									}
									{ tx.LPTokenOut &&
									<tr>
										<td>LPTokenOut:</td>
										<td>
											<Money value={tx.LPTokenOut.value} currency={tx.LPTokenOut.currency} issuer={tx.LPTokenOut.issuer} drops />
										</td>
									</tr>
									}
									{ tx.LPTokenIn &&
									<tr>
										<td>LPTokenIn:</td>
										<td>
											<Money value={tx.LPTokenIn.value} currency={tx.LPTokenIn.currency} issuer={tx.LPTokenIn.issuer} drops />
										</td>
									</tr>
									}

									{ tx.NFTokenID &&
									<tr>
										<td>NFToken ID:</td>
										<td>
											<span className="text-monospace text-muted"><Link to={`/nft/${tx.NFTokenID}`}>{tx.NFTokenID}</Link></span>
										</td>
									</tr>
									}
									{ tx.Issuer &&
									<tr>
										<td>Issuer:</td>
										<td><AccountTag link>{tx.Issuer}</AccountTag></td>
									</tr>
									}
									{ tx.Owner &&
									<tr>
										<td>Owner:</td>
										<td><AccountTag link>{tx.Owner}</AccountTag></td>
									</tr>
									}
									{ tx.NFTokenTaxon >= 0 &&
									<tr>
										<td>Taxon:</td>
										<td>
											<XIconBadge variant="success" icon="layer-group">{tx.NFTokenTaxon}</XIconBadge>
										</td>
									</tr>
									}
									{ tx.TransferFee >= 0 &&
									<tr>
										<td>Transfer fee:</td>
										<td>
											<span className="text-monospace">{transferFeeToPercent(tx.TransferFee)}%</span>
										</td>
									</tr>
									}
									{ tx.URI &&
									<tr>
										<td>URI:</td>
										<td>
											<span className="text-small">{hex2ascii(tx.URI)}</span>
										</td>
									</tr>
									}
									{ tx.NFTokenBuyOffer &&
									<tr>
										<td>Buy offer:</td>
										<td>
											<span className="text-monospace text-muted">{tx.NFTokenBuyOffer}</span>
										</td>
									</tr>
									}
									{ tx.NFTokenSellOffer &&
									<tr>
										<td>Sell offer:</td>
										<td>
											<span className="text-monospace text-muted">{tx.NFTokenSellOffer}</span>
										</td>
									</tr>
									}

									{ tx.Amount &&
									<tr>
										<td>Amount:</td>
										<td>
											<Money
												value={tx.Amount.value}
												currency={tx.Amount.currency}
												issuer={tx.Amount.issuer}
												drops
											/>
										</td>
									</tr>
									}

									{ tx.OperationLimit &&
									<tr>
										<td>Operation limit:</td>
										<td>
											<span>{tx.OperationLimit}</span>
										</td>
									</tr>
									}

									{ tx.NetworkID >= 0 &&
									<tr>
										<td>NetworkID:</td>
										<td>
											<span>{tx.NetworkID}</span>
										</td>
									</tr>
									}

								</tbody>
							</Table>
							{tx.Memos && tx.Memos.length > 0 &&
								<TransactionMemo memos={tx.Memos} />
							}
						</Col>

						<Col xs={12} md={4}>
							<Table responsive>
								<thead>
									<tr>
										<th>Outcome</th>
										<th><TxResult result={tx.meta.TransactionResult} /></th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Ledger:</td>
										<td><LedgerTag ledger_index={tx.ledger_index} /></td>
									</tr>
									{tx.meta && tx.meta.TransactionIndex >= 0 &&
									<tr>
										<td>Index:</td>
										<td>{tx.meta.TransactionIndex}</td>
									</tr>
									}
									<tr>
										<td>Tx seq:</td>
										<td><FormattedNumber value={tx.Sequence} /></td>
									</tr>
									<tr>
										<td>Fee:</td>
										<td>
											<Money value={tx.Fee} currency={NATIVE_CURRENCY} min={1} max={6} drops/>
										</td>
									</tr>
									{tx.meta && tx.meta.delivered_amount && isTokenBurnTx(tx) &&
									<tr className={'table-warning'}>
										<td>Burned:</td>
										<td>
											<Money
												value={tx.meta.delivered_amount.value}
												currency={tx.meta.delivered_amount.currency}
												issuer={tx.meta.delivered_amount.issuer}
												drops
											/>
										</td>
									</tr>
									}
									{tx.meta && tx.meta.delivered_amount && !isTokenBurnTx(tx) &&
									<tr className={getTxResultStyle( tx.meta.TransactionResult, 'table-' )}>
										<td>Delivered:</td>
										<td>
											<Money
												value={tx.meta.delivered_amount.value}
												currency={tx.meta.delivered_amount.currency}
												issuer={tx.meta.delivered_amount.issuer}
												drops
											/>
										</td>
									</tr>
									}
									{tx.TakerPays && tx.TakerPays.value && tx.TakerGets &&  tx.TakerGets.value &&
									<tr className={getTxResultStyle( tx.meta.TransactionResult, 'table-' )}>
										<td>Price:</td>
										<td>
											<Money
												value={this.getExchangeRate(tx.TakerPays, tx.TakerGets)}
												currency={`${currencyName(tx.TakerPays.currency)}/${currencyName(tx.TakerGets.currency)}`}
												drops
											/>
										</td>
									</tr>
									}
									{tx.TransactionType && tx.TransactionType.startsWith("Check") && tx.SendMax && tx.SendMax.value &&
									<tr className={getTxResultStyle( tx.meta.TransactionResult, 'table-' )}>
										<td>Pay upto:</td>
										<td>
											<Money
												value={tx.SendMax.value}
												currency={tx.SendMax.currency}
												issuer={tx.SendMax.issuer}
												drops
											/>
										</td>
									</tr>
									}
									{tx.TransactionType === "EnableAmendment" && tx.Amendment &&
									<tr className={getEnableAmendmentStyle(tx.Flags, 'table-')}>
										<td>Status:</td>
										<td className="text-uppercase"><XBadge variant={getEnableAmendmentStyle(tx.Flags)}>{getAmendmentStatus(tx.Flags)}</XBadge></td>
									</tr>
									}
									{tx.TransactionType === "TicketCreate" && tx.TicketCount > 0 &&
									<tr className={getTxResultStyle( tx.meta.TransactionResult, 'table-' )}>
										<td>Tickets created:</td>
										<td>
											{tx.TicketCount} <XIcon icon="ticket-alt" size="lg" className="ml-1 text-success"></XIcon>
										</td>
									</tr>
									}
									{tx.TransactionType === TransactionType.Clawback && tx.Amount &&
									<tr className="table-primary">
										<td>Clawback:</td>
										<td>
											<XIcon icon="paw-claws" size="lg" className="mr-2 text-primary"></XIcon>
											<Money
												value={tx.Amount.value}
												currency={tx.Amount.currency}
												issuer={tx.Amount.issuer}
												drops
											/>
										</td>
									</tr>
									}
									{tx.TransactionType === TransactionType.EscrowCreate && Number(getEscrowNodeAmount(tx?.meta?.AffectedNodes)) >= 0 &&
									<tr className="table-success">
										<td>Escrowed:</td>
										<td><Money value={getEscrowNodeAmount(tx?.meta?.AffectedNodes)} drops /></td>
									</tr>
									}
									{tx.TransactionType === TransactionType.EscrowFinish && Number(getEscrowNodeAmount(tx?.meta?.AffectedNodes)) >= 0 &&
									<tr className="table-success">
										<td>Released:</td>
										<td><Money value={getEscrowNodeAmount(tx?.meta?.AffectedNodes)} drops /></td>
									</tr>
									}

									{tx.TransactionType &&
									Burn2MintTransactionTypes.includes(tx.TransactionType) &&
									tx.OperationLimit &&
									Number(tx.OperationLimit) === XrplNetwork.XahauMainnet &&
									<tr className={'table-warning'}>
										<td>Burn2Mint:</td>
										<td>
											<AccountTag namea={tx.AccountName} link minimal network_id={Number(tx.OperationLimit)}>{tx.Account}</AccountTag>&rarr;
										</td>
									</tr>
									}

									{ tx.PriceDataSeries && Array.isArray(tx.PriceDataSeries) && tx.PriceDataSeries.length > 0 &&
									<>
									<tr className='table-success'>
										<td colSpan="2">
											Price data series:
										</td>
									</tr>
									{tx.PriceDataSeries.map((data, index) =>
									<tr key={index}>
										<td>
											<XBadge variant="info text-monospace">{currencyName(data?.PriceData?.BaseAsset)}/{currencyName(data?.PriceData?.QuoteAsset)}</XBadge>
										</td>
										<td>
											<span className='text-monospace'>
												<XBadge variant="secondary">{getPrice(data?.PriceData?.AssetPrice, data?.PriceData?.Scale) || "DELETED"}</XBadge>
											</span>
										</td>
									</tr>
									)}
									</>
									}

								</tbody>
							</Table>
						</Col>
					</Row>
				</Card.Body>
				<Card.Footer>
					<ButtonToolbar>
					  <Button variant="outline-info" size="sm" onClick={this.handleShow}>Raw JSON</Button>
		        <Modal
		        	show={this.state.show}
		        	onHide={this.handleClose}
		        	size="lg"
		        	aria-labelledby="contained-modal-title-vcenter"
        			centered
      			>
		          <Modal.Header closeButton>
		            <Modal.Title>Raw transaction</Modal.Title>
		          </Modal.Header>
		          <Modal.Body>
		          	<ReactJson
		          		src={tx}
		          		collapseStringsAfterLength={64}
		          		enableClipboard={false}
		          		indentWidth={2}
	          		/>
	          	</Modal.Body>	
		          <Modal.Footer>
		            <Button variant="secondary" onClick={this.handleClose}>
		              Close
		            </Button>
		          </Modal.Footer>
		        </Modal>
					</ButtonToolbar>
				</Card.Footer>
			</Card>
		);
	}
}

TransactionInfo.propTypes = {
	tx: PropTypes.object.isRequired,
	error: PropTypes.object,
}

export default TransactionInfo;
